@media (min-width:270px)  {

     .about-page{
        .text-zone{
            width: 70%;
            left: 22%;
            h1{
            font-size: 2em;
            letter-spacing: 5px;
            } 
            p{
                font-size: 1.5em;
            }
        }

    }
    .stage-cube-cont{
        width: 25%;
        height: 90%;
        top: 50%;
        padding-top: 25%;
        margin-left: 1em;
        position: absolute;
        right: 0;
        overflow: hidden;
    }
    
    .cubespinner{
        
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 3em 3em 0;
    margin-left: calc(50% - 3em);
    
    div{
        position: absolute;
        width: 2em;
        height: 2em;
        border: 1px solid #ccc;
        background: rgba(255,255,255,0.4);
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 1em 0 lightyellow;
        }
    
        .face1{
            transform: translateZ(1em);
        }
        .face2{
            transform: rotateY(90deg) translateZ(1em);
        }
        .face3{
            transform: rotateY(90deg) rotateX(90deg) translateZ(1em);
        }
        .face4{
            transform: rotateY(180deg) rotateZ(90deg) translateZ(1em);
        }
        .face5{
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(1em);
        }
        .face6{
            transform: rotateX(-90deg) translateZ(1em);
        }
        
    }
    
    @keyframes spincube{
        from,
        to {
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }
        16%{
            transform:  rotateY(-90deg);
        }
        33%{
            transform:  rotateY(-90deg) rotateZ(90deg);
        }
        50%{
            transform:  rotateY(-180deg) rotateZ(90deg);
        }
        66%{
            transform:  rotateY(-270deg) rotateZ(90deg);
        }
        83%{
            transform:  rotateY(90deg);
        }
    }
    
    .loader-active{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        animation: fadeOut 1s 1s;
        animation-fill-mode: forwards;
    }
}


@media (min-width:1281px) {
    .about-page{
        .text-zone{
            width: 80%;
            left: 10%;
            h1{
            font-size: 5em;
            letter-spacing: 0.3em;
            } 
            p{
                font-size: 1.7em;
            }
        }

    }

    .stage-cube-cont{
        min-width: 50%;
        height: 100%;
        top: 25em;
        padding-top: 18%;
        margin-left: 0;
        position: absolute;
        right: 0;
        overflow: hidden;
    }
    
    .cubespinner{
        animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    
    div{
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255,255,255,0.4);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0 lightyellow;
        }
    
        .face1{
            transform: translateZ(100px);
        }
        .face2{
            transform: rotateY(90deg) translateZ(100px);
        }
        .face3{
            transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        }
        .face4{
            transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        }
        .face5{
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        }
        .face6{
            transform: rotateX(-90deg) translateZ(100px);
        }
        
    }
    
    @keyframes spincube{
        from,
        to {
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }
        16%{
            transform:  rotateY(-90deg);
        }
        33%{
            transform:  rotateY(-90deg) rotateZ(90deg);
        }
        50%{
            transform:  rotateY(-180deg) rotateZ(90deg);
        }
        66%{
            transform:  rotateY(-270deg) rotateZ(90deg);
        }
        83%{
            transform:  rotateY(90deg);
        }
    }
    
    .loader-active{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        animation: fadeOut 1s 1s;
        animation-fill-mode: forwards;
    }
}
