@media (min-width:1281px) {html {

    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}



.projects-container{
    
    margin: auto;
    padding: 70px;
    top: 20%;
    left: 5%;
    right: 5%;
    width: 100%;
    
    text-align: center;

    h1{
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        text-align: center;
        letter-spacing: 10px;
    
        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            lefT: -10px;
            opacity: 0.6;
            line-height: 18px;
        }
    
        &::after{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            opacity: 0.6;
        }
    }
    
    .text-animate-hover{
        &:hover{
            color: #fff;
        }
    }
}



}

//-------------->>MOBILE RESPONSIVE<<------------------
@media (min-width:280px) {html {

    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}



.projects-container{
    
    margin: auto;
    padding: 1em;
    top: 20%;
    left: 5%;
    right: 5%;
    width: 100%;
    
    text-align: center;
}


h1{
    font-size: 3em;
    font-family: 'Coolvetica';
    color: #ffffff;
    font-weight: 400;
    margin-top: 0.6em;
    position: relative;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: 10px;

    &::before{
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        lefT: -10px;
        opacity: 0.6;
        line-height: 18px;
    }

    &::after{
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
    }
}

.text-animate-hover{
    &:hover{
        color: rgb(0, 188, 250);
    }
}
}


