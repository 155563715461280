@media (max-width:800px)  {
    .contact-page {
        .text-zone{
            width: 100%;
            
            margin-left: 10%;
            h1{
                font-size: 2.5em;
                margin-right: 30%;
            }
            p{
                font-size: 0.8em;
            }
            
        }
    }

    .contact-form{
        
        width: 100%;
        
        
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        height: 50px;
    
        ul{
            padding: 0;
            height: 100%;
            top: 30%;
            margin: 0;
    
            li{
                padding: 0;
                margin: 0;
                list-style: none;
                margin-bottom : 0.5em;
                overflow: hidden;
                display: block;
                position: relative;
                opacity: 0;
                animation: fadeInUp 2s 2s;
                animation-fill-mode: forwards;
                clear: both;
            }
        }
    
        li.half{
            width: 100%;
            
            float: left;
            clear: none;
    
            &:first-child{
                margin-left: 0;
            }
        }
    }
    
    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background-color: #115173;
        height: 2em;
        font-size: 1em;
        color: #fff;
        padding: 0 1em;
        box-sizing: border-box;
    }
    
    textarea{
        width: 100%;
        border: 0;
        background: #115173;
        height: 2em;
        font-size:16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    
    }
    
    .flat-button{
        color: #0ff;
        background: 0 0;
        font-size:1.3em ;
        letter-spacing: 7px;
        text-decoration: none;
        padding: 0.7em 1em;
        margin-left: 10px;
        
    
        border: 3px solid #0ff;
        float: center;
        border-radius: 10px;
        width: 40%;
    
        &:hover{
            border-color: #afa;
            color: #afa;
        }
    }
    
    .info-map{
        position: absolute;
        background-color: #000;
        top: 2em;
        right: 5%;
        z-index: 999999;
        width: 11em;
        padding: 1em;
        color: #fff;
        font-family: 'Helvetica ';
        font-size: 1em;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 1s 1.5s;
        animation-fill-mode: forwards;
    
        span{
            font-size: 1em;
            display: block;
            padding-top:1em;
            color: #ffd700;
        }
    
    }
    
    .map-wrap{
        background-color: rgba(8,253,216,0.1);
        position: relative;
        margin-left: auto;
        margin-right: 20px;
        width: 100%;
        height: 50px;
        
        width: 100%;
        height: 30%;
        margin-top: 10%;
    }
    
    .leaflet-container{
        
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        animation: backInRight 1s 1.2s;
        animation-fill-mode: forwards;
    }
}


// -------->> DESKTOP <<--------------
@media (min-width:1280px)  {
    .contact-form {
        width: 100%;
        margin-top: 360px;
      
        ul {
          padding: 0;
          margin: 0;
      
          li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            opacity: 0;
            overflow: hidden;
            display: block;
            clear: both;
            position: relative;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
          }
      
          li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;
      
            &:first-child {
              margin-left: 0;
            }
          }
        }
      
        input[type='text'],
        input[type='email'] {
          width: 100%;
          border: 0;
          background: #115173;
          height: 50px;
          font-size: 16px;
          color: #fff;
          padding: 0 20px;
          box-sizing: border-box;
        }
      
        textarea {
          width: 100%;
          border: 0;
          background: #115173;
          height: 50px;
          font-size: 16px;
          color: #fff;
          padding: 20px;
          box-sizing: border-box;
          min-height: 150px;
        }
      
        .flat-button{
            color: #0ff;
            background: 0 0;
            font-size:1.3em ;
            letter-spacing: 7px;
            text-decoration: none;
            padding: 0.7em 1em;
            margin-left: 10px;
            
        
            border: 3px solid #0ff;
            float: center;
            border-radius: 10px;
            width: 40%;
        
            &:hover{
                border-color: #afa;
                color: #afa;
            }
        }
      }
      
      .map-wrap {
        background: rgba(8, 253, 216, 0.1);
        float: right;
        width: 53%;
        height: 115%;
        margin-top: -3%;
      }
      
      .leaflet-container {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 0;
        animation: backInRight 1s 1.2s;
        animation-fill-mode: forwards;
      }
      
      .info-map {
        position: absolute;
        background: #000;
        top: 50px;
        right: 30%;
        z-index: 999999;
        width: 220px;
        padding: 20px;
        color: #fff;
        font-family: 'Helvetica';
        font-size: 17px;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 1s 1.5s;
        animation-fill-mode: forwards;
      
        span {
          font-size: 16px;
      
          span {
            color: #ffd700;
          }
        }
      }
      
      @media screen and (max-width: 1200px) {
        .map-wrap {
          float: none;
          margin: 0;
          width: 100%;
          height: 400px;
        }
        
        .info-map {
          display: none;
        }
      }
    }