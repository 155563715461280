@media(max-width:2600px) {
  .slide-container{
  padding-top: 20px;
  width: 60%;
  margin: auto; 
  
}


h3{
  text-align: center; 
  padding-bottom: 50px;
  font-family: sans-serif;
  color: #afa;
  font-size: 20px;
  padding-right: 80px;
  opacity: 2;
  animation: fadeIn 1s 1.7s backwards;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 65em;
  opacity: 2;
  animation: fadeIn 1s 1.7s backwards;
  
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
  
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}}


// -------->>MOBILE RESPONSIVE<<--------------------
@media (max-width:800px) {.slide-container {
  padding-top: 1em;
  width: 75%;
  margin: auto; 
  
}


h3{
  text-align: center; 
  padding-bottom: 2em;
  font-family: sans-serif;
  color: #afa;
  font-size: 20px;
  padding-right: 80px;
  opacity: 2;
  animation: fadeIn 1s 1.7s backwards;
  

  


}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 18em;
  width: auto;
  opacity: 2;
  animation: fadeIn 1s 1.7s backwards;
  
}

.each-slide span {
  padding: 20px;
  font-size: 1em;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
  
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}}
