@media (min-width:320px)  {
      
     .home-page{

        .text-zone{
            position: absolute;
            lefT: 25%;
            top: 50%;
            transform: translateY(-50%);
            width: 40%;
            max-height: 90%;
            h1{
                color: #fff;
                font-size: 2em;
                margin: 0;
                font-family: 'Coolvetica';
                font-weight: 400;
                text-align: left;
        
                &::before{
                    content: '<h1>';
                    font-family: 'La Belle Aurore';
                    color: #ffd700;
                    font-size: 18px;
                    position: absolute;
                    margin-top: -40px;
                    left: 15px;
                    opacity: 0.6;
        
                }
        
                &::after{
                    content: '<h1>';
                    font-family: 'La Belle Aurore';
                    color: #ffd700;
                    font-size: 18px;
                    position: absolute;
                    margin-top: 18px;
                    margin-left: 20px;
                    animation: fadeIn 1s 1.7s backwards;
                    opacity: 0.6;
                }
                
            }
    
        }
        
    
        h2{
        color: #8d8d8d;
        margin-top: 30px;
        font-weight: 400;
        font-size: 1em;
        font-family: sans-serif;
        letter-spacing: 2px;
        animation: fadeIn 1s 1.8s backwards;
    
        }
    
        .flat-button{
            color: #0ff;
            font-size: 1em;
            font-weight: 400;
            letter-spacing: 5px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 1px solid #0ff;
            margin-top: 40px;
            float: left;
            width: 70%;
            animation: fadeIn 1s 1.8s backwards;
            white-space: nowrap;
    
            &:hover{
                background: #afa;
                color: #000;
            }
        }
    
        
    }

    }

    
@media (min-width:1281px) {
    .container{
        width: 100%;
        will-change: contents;
        height: 90%;
        min-height: 566px;
        position: absolute;
        opacity: 0;
        top: 5%;
        margin: 0 auto;
        z-index: 1;
        transform-style: preserve-3d;
        animation: fadeIn 1s forwards;
        animation-delay: 1s;

        .home-page{
        

            .text-zone{
                position: absolute;
                lefT: 10%;
                top: 50%;
                transform: translateY(-50%);
                width: 40%;
                max-height: 90%;
                
            h1{
                color: rgb(244, 244, 244);
                font-size: 53px;
                margin: 0;
                font-family: 'Coolvetica';
                font-weight: 400;
                text-align: left;
        
                &::before{
                    content: '<h1>';
                    font-family: 'La Belle Aurore';
                    color: #ffd700;
                    font-size: 18px;
                    position: absolute;
                    margin-top: -40px;
                    left: 15px;
                    opacity: 0.6;
        
                }
        
                &::after{
                    content: '<h1>';
                    font-family: 'La Belle Aurore';
                    color: #ffd700;
                    font-size: 18px;
                    position: absolute;
                    margin-top: 18px;
                    margin-left: 20px;
                    animation: fadeIn 1s 1.7s backwards;
                    opacity: 0.6;
                }
                
            }
        
            }
        
            h2{
            color: #8d8d8d;
            margin-top: 20px;
            font-weight: 400;
            font-size: 11px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
        
            }
        
            .flat-button{
                color: #0ff;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 7px;
                font-family: sans-serif;
                text-decoration: none;
                padding: 10px 18px;
                border: 1px solid #0ff;
                margin-top: 25px;
                float: left;
                width: 35%;
                animation: fadeIn 1s 1.8s backwards;
                white-space: nowrap;
        
                &:hover{
                    background: #afa;
                    color: #000;
                }
            }
        
            
        }
    }
    
}
